import * as yup from 'yup';

import { useAcceptResultsInTask } from '../../../../../hooks/useAcceptResultsInTask';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import {
  TaskAcceptResultsFields,
  TaskNanoID,
  TaskUUID
} from '../../../../../tasksTypes';
import { ReactHookFormMode } from '../../../../../../common/hooks/base/useReactHookForm/useReactHookForm.types';
import { AcceptResultsInTaskFormData } from '../../AcceptResultsInTaskForm.types';

import {
  ACCEPT_RESULTS_IN_TASK_QUERY,
  AcceptResultsInTaskQueryResponse
} from '../../../../../queries/acceptResultsInTask.query';

import { formsErrors } from '../../../../../../../locales/keys';
import { TaskCache } from '../../../../../TaskCache';
import { TaskProcessingJobCache } from '../../../../../../taskProcessingJobs/TaskProcessingJobCache';

const acceptResultsInTaskFormData: AcceptResultsInTaskFormData = {
  mark: 5
};

interface AcceptResultsInTaskFormOptions {
  taskUuid: TaskUUID;
  taskNanoId: TaskNanoID;
  withoutAccept?: boolean;
  withoutClose?: boolean;
}

const acceptTaskValidationSchema = yup.object({
  [TaskAcceptResultsFields.MARK]: yup
    .number()
    .min(1, formsErrors.required)
    .nullable(true)
    .required(formsErrors.required)
});

function useAcceptResultsInTaskForm({
  taskUuid,
  taskNanoId,
  withoutAccept,
  withoutClose
}: AcceptResultsInTaskFormOptions) {
  const currentUser = useCurrentUser();

  const {
    handleSubmitReactHookForm,
    register,
    errors,
    control,
    resetForm,
    watch,
    isValid
  } = useReactHookForm<AcceptResultsInTaskFormData>({
    defaultValues: acceptResultsInTaskFormData,
    schema: acceptTaskValidationSchema,
    mode: ReactHookFormMode.ON_CHANGE
  });

  const {
    acceptResultsInTask,
    acceptResultsInTaskLoading,
    acceptResultsInTaskErrorMessage,
    acceptResultsInTaskReset
  } = useAcceptResultsInTask<AcceptResultsInTaskQueryResponse>({
    query: ACCEPT_RESULTS_IN_TASK_QUERY,
    cacheKeys: [
      TaskCache.indexCacheKey(),
      TaskCache.showSidebarCacheKey(),
      TaskCache.showDashboardCacheKey(),
      TaskCache.showHeaderActionsCacheKey(),
      TaskCache.showQueryKey(taskNanoId),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId),
      TaskCache.compactIndexCacheKey(),
      TaskCache.compactTimelineCacheKey(),
      TaskCache.userTasksCacheKey(currentUser?.nanoId),
      TaskProcessingJobCache.taskTaskProcessingJobCacheKey(taskNanoId)
    ]
  });

  return {
    control,
    acceptResultsInTask,
    acceptResultsInTaskLoading,
    acceptResultsInTaskErrorMessage,
    acceptResultsInTaskReset,
    isValid,
    resetForm,
    handleAcceptResultsInTask: handleSubmitReactHookForm({
      onSubmit: async (data: AcceptResultsInTaskFormData) =>
        acceptResultsInTask({
          withoutAccept,
          withoutClose,
          uuid: taskUuid,
          async: true,
          ...data
        }),
      dirtyFieldsOnly: false
    }),
    registerSupportMark: register(TaskAcceptResultsFields.MARK),
    validationErrors: {
      markValidationError: errors?.mark?.message
    },
    watchMark: watch(TaskAcceptResultsFields.MARK)
  };
}

export default useAcceptResultsInTaskForm;
